import React, {Component} from 'react'
import YouTube from 'react-youtube';
import PageHeader from '../PageComponents/PageHeader';
const ytThumbUrl = 'https://img.youtube.com/vi/';

export default class VideosPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [
        {
          title: 'Keto Staying on Track over the Holiday Season',
          id: 'ClfAnsjqTnQ'
        }, {
          title: 'What to eat for Keto success',
          id: 'ZSgnPAlPeMk'
        }, {
          title: '7 Easy Keto Snacks',
          id: 'j38PCd1fXUY'
        }, {
          title: 'Keto Success with the Alpha Lipid™ SDII™ Keto Programme',
          id: 'hYYWttg5hP8'
        }, {
          title: 'Unboxing your Alpha Lipid™ SDII™ Weight Management Pack',
          id: 'pqOyLHTrRa0'
        }, {
          title: 'Keto Made Easy With Nicole Deed – Nutritionist',
          id: 'gcYGKek-Ji8'
        }, {
          title: 'New Image Keto Walk-through',
          id: 'DG84SjDWHBs'
        }, {
          title: 'Tips to succeed on keto',
          id: 'Tv9PKnlboOo'
        }, {
          title: 'The Real Deal with Exogenous Ketones',
          id: 'V9dzMHoDGG8'
        }, {
          title: 'How Does Keto Work?',
          id: 'XdDsBtK9t_U'
        }, {
          title: 'What is keto?',
          id: 'v5t-gvv2VLc'
        }, {
          title: 'Why keto?',
          id: 'e6RY0qU4yrk'
        }, {
          title: 'New Image™ Keto 14 Day Trial',
          id: 'jeczPc99sH8'
        }, {
          title: '10 Tips To Succeed On Keto with Alpha Lipid™ SDII™',
          id: '59NzLqsf3FA'
        }, {
          title: '8 Fat Loss Mistakes',
          id: 'HnPsHm7CsVM'
        }, {
          title: 'Preparing a drink of Alpha Lipid SDII',
          id: 'v_qpuFhjD2Q'
        }
      ],
      open: false
    }
  }
  closeVideoModal() {
    this.setState({open: false, playing: ''})
  }
  playVideo(key) {
    this.setState({open: true, playing: this.state.videos[key].id})
  }
  render() {
    const {open, videos} = this.state;
    const videoTiles = videos.map((video, key) => <div key={key} className="w-full md:w-6/12 lg:w-4/12">
      <div className="md:px-5 py-5">
        <button
          onClick={this
          .playVideo
          .bind(this, key)}
          className="text-left">
          <img src={`${ytThumbUrl}${video.id}/maxresdefault.jpg`} alt={video.title} width="1270" height="720"/>
          <h4 className="my-2 text-sm leading-tight text-secondary font-bold">{video.title}</h4>
        </button>
      </div>
    </div>);
    return (
      <main>
        <PageHeader mainHeading="Videos" subHeading="Keto video library"/>
        <div className="relative my-10">
          <div className="pageGrid">
            <div className="flex flex-wrap">
              {videoTiles}
            </div>
          </div>
          <VideoModal
            close={this
            .closeVideoModal
            .bind(this)}
            playing={this.state.playing}
            open={open}/>
        </div>
      </main>
    )
  }
}

function VideoModal(props) {
  const {open} = props;
  const opts = {
    playerVars: {
      autoplay: 1
    }
  };
  return (
    <div
      className={open
      ? 'fixed top-0 left-0 h-screen w-full z-50 left-0'
      : 'hidden'}>
      <button
        className="bg-black bg-opacity-75 absolute w-full top-0 left-0 h-full focus:outline-none"
        onClick={props.close} aria-label="close"><span className="hidden">close</span></button>
      <div className="flex items-center h-full w-full">
        <div className="pageGrid">
          <div className="w-full embed-container">
            <YouTube
              videoId={props.playing}
              opts={opts}
              className="videoFrame"
              onEnd={props.close}/>
          </div>
        </div>
      </div>
    </div>
  )
}